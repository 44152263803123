.disabled {
  background-color: #c8c8c8;
  opacity: 0.4;
}

.selected {
  background-color: #00D963;
  color: white;
}

.button {
  background-color: #00D963;
  height: 40rem;
  justify-content: center;
  align-items: center;
}

.white-bg {
  background: white;
}

.center {
  margin: 0 auto;
  width: 60% !important;
  /* PARA SOBRESCREVER O WIDTH DO react-calendar QUE NO DJANGO FICA SEM MUDAR */
}

@media (max-width: 500px) {
  .center {width: 100% !important;}
}
